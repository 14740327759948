<template>
  <div>
    <div v-if="!status">
      <h1 class="text-xl xl:text-4xl pb-8 flex justify-center">
        {{ $t('status.heading') }}
      </h1>
      <Loader id="polling-loader" :title="$t('payment.processing')" />
    </div>
    <component
      v-else-if="mapStatusToComponent()"
      :is="mapStatusToComponent()"
      :isReferral="isReferral"
      :isParqEnabled="isParqEnabled"
      :isInductionEnabled="isInductionEnabled"
      :isMPayStripe="isMPayStripe"
      :finalizeInClub="finalizeInClub"
    />
  </div>
</template>

<script>
import paymentStatusPoller from '@/payments/payment-status-poller';
import paymentModes from '@/payments/payment-modes';
import paymentStatuses from '@/api/payment-statuses';
import SuccessSummary from '@/components/status/SuccessSummary.vue';
import SuccessSummaryPayInClub from '@/components/status/SuccessSummaryPayInClub.vue';
import SuccessSummaryMPay from '@/components/status/SuccessSummaryMPay.vue';
import FailureSummary from '@/components/status/FailureSummary.vue';
import DelaySummary from '@/components/status/DelaySummary.vue';
import CancelSummary from '@/components/status/CancelSummary.vue';
import RejectSummary from '@/components/status/RejectSummary.vue';
import InductionSummary from '@/components/status/InductionSummary.vue';
import ParqQuestionsSummary from '@/components/status/ParqQuestionsSummary.vue';
import { Loader } from '@membr/component-library';
import { Amplitude, EventType } from '@/amplitude';
import Analytics from '@/api/analytics';

export default {
  name: 'Status',
  components: {
    SuccessSummary,
    SuccessSummaryPayInClub,
    SuccessSummaryMPay,
    FailureSummary,
    DelaySummary,
    CancelSummary,
    RejectSummary,
    InductionSummary,
    ParqQuestionsSummary,
    Loader
  },
  props: {
    paymentIdentifier: { type: String, required: false },
    statusProp: { type: String, required: false },
    finalizeInClubProp: { type: Boolean, require: false, default: false },
    isReferralProp: { type: Boolean, require: false, default: false },
    pollingInterval: { type: Number, default: 1000 }
  },
  data() {
    return {
      status: this.statusProp,
      finalizeInClub: this.finalizeInClubProp,
      isReferral: this.isReferralProp,
      isMPayStripe: false,
      isParqEnabled: false,
      isInductionEnabled: false,
      isSubmited: false,
      userId: '',
      hash: ''
    };
  },
  computed: {
    gymId() {
      return this.$store.state.context.gymId;
    },
    hostname() {
      return this.$store.state.hostname;
    },
    isParqQuestionSubmited() {
      return this.$route.query.isSubmited ? this.$route.query.isSubmited : false;
    },
    enableParqQuestionOnSignup() {
      return this.$store.state.context.memberSignup.enableParqQuestionOnSignup;
    }
  },
  async created() {
    if (!this.status) {
      await paymentStatusPoller.pollStatus(
        this.gymId,
        this.enableParqQuestionOnSignup,
        this.paymentIdentifier,
        this.paymentStatusChangedCallback,
        this.pollingInterval
      );
    }
  },
  methods: {
    mapStatusToComponent() {
      switch (this.status) {
        case paymentStatuses.succeeded.key:
          if (this.isInductionEnabled && !this.isParqEnabled) {
            return 'InductionSummary';
          } else if (this.isParqEnabled) {
            return 'ParqQuestionsSummary';
          } else if (this.finalizeInClub) {
            return 'SuccessSummaryPayInClub';
          } else if (this.isMPayStripe) {
            return 'SuccessSummaryMPay';
          } else {
            return 'SuccessSummary';
          }
        case paymentStatuses.delayed.key:
          return 'DelaySummary';
        case paymentStatuses.failed.key:
          return 'FailureSummary';
        case paymentStatuses.cancelled.key:
          return 'CancelSummary';
        case paymentStatuses.rejected.key:
          return 'RejectSummary';
      }
    },
    paymentStatusChangedCallback(status) {
      if (status.ongoingPaymentType === paymentModes.bank && this.$store.state.context.countryCode === 'JP') {
        this.finalizeInClub = true;
      }
      this.isReferral = status.isReferral;
      this.status = status.value.key;
      this.isMPayStripe = status.isMPayStripe;
      this.isParqEnabled = status.isParqEnabled;
      this.userId = status.userId;
      this.hash = status.hash;
      this.isInductionEnabled = status.isInductionEnabled;

      this.$store.commit('setUserId', status.userId);
      this.$store.commit('setHash', status.hash);

      if (status.value.terminal) {
        Amplitude.raiseEvent(EventType.SignupCompleted, { status: status.value.key });
      }

      if (status.value.key === paymentStatuses.succeeded.key) {
        Analytics.trackEvent(Analytics.events.membershipConfirmation);
      }
    }
  }
};
</script>
