import store from '@/store';
import axios from 'axios';

const PHP_API_URL = process.env.VUE_APP_PHP_API_BASE_URL;

export default {
  events: {
    packagesAvailable: 'Packages available',
    packageSelected: 'Package selected',
    membershipConfirmation: 'Membership confirmation',
    membershipSetupCompleted: 'Membership setup completed',
    membershipSetupBackToPersonalDetails: 'Membership setup then back to Personal details',
    contactDetailsCompleted: 'Contact details completed',
    contactDetailsBackToPackage: 'Contact details then back to Package selection',
    membershipAgreementSubmitted: 'Membership agreement submitted',
    membershipAgreementBackSelected: 'Membership agreement back selected',
    promoCodeEntered: 'Promo code entered',
    referralCodeEntered: 'Referral code entered'
  },
  async trackEvent(name, properties) {
    const defaultProperties = {
      locale: store.state.context.locale,
      host: store.state.hostname,
      app: 'online-signup'
    };
    const allProperties = { ...defaultProperties, ...properties };
    const url = `${PHP_API_URL}public/analytics`;
    await axios.post(url, { eventName: name, eventProperties: allProperties });
  }
};
